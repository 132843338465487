<template>
  <div class="cursor-pointer" @click="$emit('click')">
    <div>
      <img
        class="rounded-full object-cover border-8 border-transparent"
        :class="{ 'border-primary': isActive }"
        :src="require(`@/assets/images/${imageUrl}`)"
        alt="author"
        loading="lazy"
        width="88"
        height="88"
      />
    </div>
    <h5 class="text-white mt-4" :class="{ 'text-blackish': isAlternate }">
      {{ author }}
    </h5>
    <h5
      class="text-grey-350 mt-4"
      :class="{ 'text-grey-450': isAlternate, 'text-primary': isActive }"
    >
      {{ description }}
    </h5>
  </div>
</template>

<script>
export default {
  name: "TestimonialAuthor",
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
  props: {
    description: {
      type: String,
      required: true,
    },
    imageUrl: {
      type: String,
      required: true,
    },
    author: {
      type: String,
      required: true,
    },
    isActive: {
      type: Boolean,
      required: true,
    },
    isAlternate: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
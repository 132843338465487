<template>
  <div>
    <h5 class="text-white">{{ title }}</h5>

    <ul class="mt-4 space-y-4">
      <li v-for="link in links" :key="link.name">
          <router-link class="text-base	text-grey-350 hover:text-white" :to="link.path">{{ link.name}}</router-link>
      </li>
    </ul>
  </div>
</template>

<script>
    export default {
        name: 'FooterLinkList',
        props: {
            links: {
                type: Array,
                default: () => [] 
            },
            title: {
                type: String,
                required: true
            }
        },
    }
</script>

<style lang="scss" scoped>
</style>
<template>
  <div class="flex flex-row justify-between items-center py-8">
    <router-link to="/">
      <component :is="isComponent"></component>
    </router-link>
    <ul class="flex space-x-6">
      <router-link
        v-for="link of navLinks"
        :key="link.name"
        class="hover:text-primary"
        :class="{
          'text-primary font-semibold': isActiveRoute(link.path),
          'text-white': isAlternate,
        }"
        :to="link.path"
        >{{ link.name }}</router-link
      >
    </ul>
    <router-link to="/contact-us" class="btn btn--primary">Talk to an Expert</router-link>
  </div>
</template>

<script>
export default {
  name: "Navbar",
  data() {
    return {
      navLinks: [
        {
          path: "/about",
          name: "About",
        },
        {
          path: "/programs",
          name: "Programs",
        },
        {
          path: "/enterprise",
          name: "Enterprise",
        },
        {
          path: "/curriculum",
          name: "Curriculum",
        },
        {
          path: "/FAQ",
          name: "FAQ",
        },
        {
          path: "/become-a-mentor",
          name: "Become a mentor",
        },
      ],
    };
  },
  props: {
    isAlternate: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Logo: () => import("@/components/blocks/Logo"),
    LogoAlternate: () => import("@/components/blocks/LogoAlternate"),
  },
  computed: {
    isComponent() {
      return !this.isAlternate ? "Logo" : "LogoAlternate";
    },
  },
  mounted() {
    this.isActiveRoute();
  },
  methods: {
    isActiveRoute(path) {
      return path && this.$route.path.indexOf(path) === 0;
    },
  },
};
</script>
<template>
  <div class="grid grid-cols-4 gap-x-10 mt-20 relative">
    <div class="">
      <div class="flex items-center justify-between">
        <div
          class="w-32 h-32 rounded-full bg-primary flex items-center justify-center relative z-20"
        >
          <ProcessOne />
        </div>
        <GreenChevronOne class="relative z-20 right-10" />
      </div>
      <h5 class="text-primary mt-14">
        Pre-course Work and Interactive Quizzes
      </h5>
      <p class="text-smoke text-xl leading-8 mt-4">
        Complete the pre-course work if you are new to coding or want to prepare
        for the coding challenges.
      </p>
    </div>
    <div>
      <div class="flex items-center justify-between">
        <div
          class="w-32 h-32 rounded-full bg-primary flex items-center justify-center relative z-20"
        >
          <ProcessTwo />
        </div>
        <GreenChevronTwo class="relative z-20 right-10" />
      </div>
      <h5 class="text-primary mt-14">Cohort Matching</h5>
      <p class="text-smoke text-xl leading-8 mt-4">
        Show us that you can think as a software developer by solving a few
        simple algorithmic coding challenges.
      </p>
    </div>

    <div>
      <div class="flex items-center justify-between">
        <div
          class="w-32 h-32 rounded-full bg-primary flex items-center justify-center relative z-20"
        >
          <ProcessThree />
        </div>
        <GreenChevronThree class="relative z-20 right-10" />
      </div>
      <h5 class="text-primary mt-14">Trails</h5>
      <p class="text-smoke text-xl leading-8 mt-4">
        Get paired to 3 other applicants and build a collaborative project with
        each of them.
      </p>
    </div>

    <div>
      <div class="flex items-center justify-between">
        <div
          class="w-32 h-32 rounded-full bg-primary flex items-center justify-center relative z-20"
        >
          <ProcessFour />
        </div>
      </div>
      <h5 class="text-primary mt-14">Fulltime Program</h5>
      <p class="text-smoke text-xl leading-8 mt-4">
        If you pass all the previous steps, you will be invited to become a
        full-time Ydev student.
      </p>
    </div>
    <div
      class="absolute top-14 h-0 w-10/12 border-dashed border-emerald-550 border-2 z-10"
      style="margin-top: 5px"
    ></div>
  </div>
</template>

<script>
import ProcessOne from "../../../public/svg/quiz-icon.svg";
import ProcessTwo from "../../../public/svg/console-icon.svg";
import ProcessThree from "../../../public/svg/trail-icon.svg";
import ProcessFour from "../../../public/svg/badge.svg";
import GreenChevronOne from "../../../public/svg/green-chevron-1.svg";
import GreenChevronTwo from "../../../public/svg/green-chevron-2.svg";
import GreenChevronThree from "../../../public/svg/green-chevron-3.svg";

export default {
  name: "ProcessList",
  components: {
    ProcessOne,
    ProcessTwo,
    ProcessThree,
    ProcessFour,
    GreenChevronOne,
    GreenChevronTwo,
    GreenChevronThree,
  },
};
</script>

<style lang="scss" scoped>
</style>
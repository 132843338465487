<template>
  <div class="px-20">
    <div class="w-full max-w-7xl mx-auto">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "Container",
};
</script>

<template>
  <Container class="bg-white py-40" :class="{'bg-accent' : isAlternate}">
    <div class="max-w-3xl">
      <h1>{{ title }}</h1>
      <p class="text-grey-350 text-xl leading-8 mt-6">
        {{ description }}
      </p>
    </div>
    <div class="grid grid-cols-4 gap-y-20 mt-20">
      <PerformanceItem
        v-for="performance of performances"
        :key="performance.title"
        :metric="performance.metric"
        :icon="performance.icon"
        :title="performance.title"
      />
    </div>
  </Container>
</template>

<script>
import PerformanceItem from "@/components/blocks/PerformanceItem";
import Container from "@/components/blocks/Container";

export default {
  name: "Performances",
  data() {
    return {
      performances: [
        {
          metric: "1,200+",
          title: "Learning Hours",
          icon: "Education",
        },
        {
          metric: "80%",
          title: "Completion Rates",
          icon: "Learn",
        },
        {
          metric: "92%",
          title: "Courses Rating",
          icon: "CourseRating",
        },
        {
          metric: "11+",
          title: "Countries",
          icon: "Globe",
        },
        {
          metric: "3,500+",
          title: "Student Trained",
          icon: "Student",
        },
        {
          metric: "500+",
          title: "Total Alumni ",
          icon: "Alumni",
        },
        {
          metric: "20+",
          title: "Daily Sessions",
          icon: "Sessions",
        },
      ],
    };
  },
  components: {
    PerformanceItem,
    Container,
  },
  props: {
    isAlternate: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    }
  },
};
</script>

<style lang="scss" scoped>
</style>
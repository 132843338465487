<template>
  <div>
    <component :is="iconType"></component>
    <h5 class="mt-4">{{ title }}</h5>
    <p class="text-xl text-grey-350 mt-4">{{ info }}</p>
  </div>
</template>

<script>
const icons = {
  Book: require("../../../public/svg/book.svg"),
  Interview: require("../../../public/svg/interview.svg"),
  Mentor: require("../../../public/svg/mentor.svg"),
  Reviews: require("../../../public/svg/Reviews.svg"),
  Access: require("../../../public/svg/access.svg"),
  Slack: require("../../../public/svg/slack.svg"),
  Curriculum: require("../../../public/svg/curriculum.svg"),
};
export default {
  name: "BenefitItem",
  props: {
    title: {
      type: String,
      required: true,
    },
    info: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
      validator(value) {
        return Object.prototype.hasOwnProperty.call(icons, value);
      },
    },
  },
  computed: {
    iconType() {
      return icons[this.icon];
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
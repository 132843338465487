<template>
  <Container class="pt-44 pb-52" :class="{'bg-accent': isAlternate, 'bg-smoke': !isAlternate}">
    <div class="w-7/12">
      <h1>What makes Ydev different</h1>
      <p class="text-xl text-grey-350 mt-4">
        We are uniquely positioned to capitalize on this tremendous opportunity
        to deliver cutting-edge and culturally competent software to financial
        institutions.
      </p>
    </div>
    <div>
      <div class="grid grid-cols-4 gap-x-12 gap-y-20 mt-20">
        <BenefitItem
          v-for="benefit of benefits"
          :key="benefit.title"
          :icon="benefit.icon"
          :title="benefit.title"
          :info="benefit.info"
        />
      </div>
    </div>
  </Container>
</template>

<script>
import BenefitItem from "@/components/blocks/BenefitItem";
import Container from '@/components/blocks/Container'

export default {
  name: "Benefits",
  data() {
    return {
      benefits: [
        {
          icon: "Book",
          title: "Program Library",
          info:
            "Complete the pre-course work if you are new to coding or want to prepare for the coding challenges.",
        },
        {
          icon: "Interview",
          title: "Interview Preparation",
          info:
            "Complete the pre-course work if you are new to coding or want to prepare for the coding challenges.",
        },
        {
          icon: "Mentor",
          title: "One Dedicated Mentor",
          info:
            "Complete the pre-course work if you are new to coding or want to prepare for the coding challenges.",
        },
        {
          icon: "Reviews",
          title: "Project Reviews",
          info:
            "Complete the pre-course work if you are new to coding or want to prepare for the coding challenges.",
        },
        {
          icon: "Access",
          title: "Access to Learning Tracks",
          info:
            "Complete the pre-course work if you are new to coding or want to prepare for the coding challenges.",
        },
        {
          icon: "Slack",
          title: "Exclusive Slack Community",
          info:
            "Complete the pre-course work if you are new to coding or want to prepare for the coding challenges.",
        },
        {
          icon: "Curriculum",
          title: "Curated Curriculum and Projects",
          info:
            "Complete the pre-course work if you are new to coding or want to prepare for the coding challenges.",
        },
      ],
    };
  },
  components: {
    BenefitItem,
    Container
  },
  props: {
    isAlternate: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
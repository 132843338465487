<template>
  <div>
    <Container class="bg-accent pb-20">
      <Navbar />
      <div class="flex flex-row justify-between mt-24">
        <h1>The future of online education is here. Learn from anywhere</h1>
        <div class="flex flex-col">
          <p class="text-grey-350 text-xl leading-8">
            We are uniquely positioned to capitalize on this tremendous
            opportunity to deliver cutting-edge and culturally competent
            software to financial institutions.
          </p>
          <div class="flex flex-row mt-6 space-x-6">
            <div class="btn btn--primary">Explore all programs</div>
            <div class="btn btn--outline px-10">Learn More</div>
          </div>
        </div>
      </div>
      <div class="-ml-4 mt-16">
        <div
          class="grid grid-flow-col auto-cols-max md:auto-cols-min space-x-6 overflow-x-scroll no-scrollbar pl-4 pb-3"
        >
          <div
            class="flex flex-col flex-1 w-330"
            v-for="person in persons"
            :key="person.id"
          >
            <div class="h-96 w-full">
              <img
                class="w-full h-full object-cover"
                :src="require(`@/assets/images/${person.imageUrl}`)"
                alt="member"
                loading="lazy"
              />
            </div>
            <div class="flex justify-between p-6 shadow-md">
              <div class="">
                <p class="font-semibold">{{ person.name }}</p>
                <p class="mt-1">{{ person.role }}</p>
              </div>
              <div>
                <Google />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-8">
        <p class="text-2xl font-semibold">Our Top and Trusted Partners</p>
        <div class="flex flex-row space-x-10 mt-8">
          <Microsoft />
          <IBM />
        </div>
      </div>
    </Container>
    <Performances
      :title="'Learning opportunities with highest level performance'"
      :description="'We are uniquely positioned to capitalize on this tremendous opportunity to deliver cutting-edge and culturally competent software to financial institutions.'"
    />
    <Container class="bg-blackish pt-48 pb-40">
      <div class="w-10/12 mx-auto">
        <h1 class="text-white text-center">Better Learning. Better Results.</h1>
        <h1 class="text-white text-center">
          Ydev end to end application process
        </h1>
      </div>
      <ProcessList />
    </Container>
    <Benefits />
    <Container class="bg-white pt-48 pb-52">
      <div class="w-7/12">
        <h1>Our curriculum</h1>
        <p class="text-xl text-grey-350 mt-4">
          Our schools are designed to help you acquire skills with real-world
          applications. With project based active learning, your mastery of the
          topics translates into skills that put you in the top percentile in
          the labour market.
        </p>
      </div>
      <Curriculum />
    </Container>
    <Container class="bg-blackish pt-48 pb-52">
      <div class="w-7/12 mx-auto">
        <h1 class="text-white text-center">Testimonials from our clients</h1>
        <p class="text-grey-350 text-xl text-center mt-6">
          Our schools are designed to help you acquire skills with real-world
          applications. With project based active learning, your mastery of the
          topics translates into skills that put you in the top percentile in
          the labour market.
        </p>
      </div>

      <div class="mt-16">
        <p class="text-primary text-5xl leading-14">
          With the pressure on and millions watching the Cleveland Cavaliers
          during the NBA Playoffs, Toptal delivered the talent and expertise
          needed to launch a brand new fan engagement platform.
        </p>
      </div>

      <div class="grid grid-cols-3 gap-x-10 mt-20">
        <TestimonialAuthor
          v-for="(testimonial, index) in testimonials"
          :key="index"
          :description="testimonial.authorWork"
          :imageUrl="testimonial.imageUrl"
          :isActive="testimonial.isActive"
          :author="testimonial.author"
          @click="toggleActiveTestimonial(index)"
        />
      </div>
    </Container>
    <Container class="bg-smoke pt-44 pb-52">
      <div class="w-8/12 mx-auto">
        <h1 class="text-center">Courses and benefit compared</h1>
        <p class="text-center text-xl text-grey-350 mt-4">
          Our schools are designed to help you acquire skills with real-world
          applications. With project based active learning, your mastery of the
          topics translates into skills that put you in the top percentile in
          the labour market.
        </p>
      </div>
      <div>
        <CourseBenefitComparison />
      </div>
    </Container>
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Navbar from "@/components/blocks/Navbar.vue";
import Container from "@/components/blocks/Container";
import Microsoft from "../../public/svg/microsoft.svg";
import IBM from "../../public/svg/ibm.svg";
import TestimonialAuthor from "@/components/blocks/TestimonialAuthor";
import Footer from "../components/blocks/Footer.vue";
import Google from "../../public/svg/google-icon.svg";
import ProcessList from "@/components/blocks/ProcessList";
import Curriculum from "@/components/blocks/Curriculum";
import CourseBenefitComparison from "@/components/blocks/CourseBenefitComparison";
import Benefits from "@/components/blocks/Benefits";
import Performances from "@/components/blocks/Performances";

export default {
  name: "Home",
  data() {
    return {
      testimonials: [
        {
          authorWork: "Cleveland Cavaliers",
          author: "Conor Kenney, Director of Professional Services",
          imageUrl: "avatar.png",
          isActive: true,
        },
        {
          authorWork: "Cleveland Cavaliers",
          author: "Conor Kenney, Director of Professional Services",
          imageUrl: "avatar.png",
          isActive: false,
        },
        {
          authorWork: "Cleveland Cavaliers",
          author: "Conor Kenney, Director of Professional Services",
          imageUrl: "avatar.png",
          isActive: false,
        },
      ],
      persons: [
        {
          id: 10,
          imageUrl: "person.png",
          name: "Debo Adegbite",
          role: "Data Scientist",
        },
        {
          id: 20,
          imageUrl: "person-2.png",
          name: "Debo Adegbite",
          role: "Data Scientist",
        },
        {
          id: 30,
          imageUrl: "person-3.png",
          name: "Debo Adegbite",
          role: "Data Scientist",
        },
        {
          id: 40,
          imageUrl: "person-2.png",
          name: "Debo Adegbite",
          role: "Data Scientist",
        },
        {
          id: 50,
          imageUrl: "person.png",
          name: "Debo Adegbite",
          role: "Data Scientist",
        },
      ],
    };
  },
  components: {
    Navbar,
    Container,
    Microsoft,
    IBM,
    Benefits,
    TestimonialAuthor,
    Footer,
    Google,
    ProcessList,
    Curriculum,
    CourseBenefitComparison,
    Performances,
  },
  methods: {
    toggleActiveTestimonial(index) {
      console.log(index);
      this.testimonials = this.testimonials.map((t, idx) => {
        t.isActive = idx === index;
        return t;
      });
    },
  },
};
</script>
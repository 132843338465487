<template>
  <div>
    <component :is="iconType"></component>
    <h1 class="text-grey-350 mt-4">{{ metric }}</h1>
    <p class="text-2xl text-grey-350 leading-8">{{ title }}</p>
  </div>
</template>

<script>
const icons = {
  Education: require("../../../public/svg/education.svg"),
  Globe: require("../../../public/svg/Globe.svg"),
  Sessions: require("../../../public/svg/Sessions.svg"),
  Student: require("../../../public/svg/Student.svg"),
  Alumni: require("../../../public/svg/Alumni.svg"),
  CourseRating: require("../../../public/svg/CourseRating.svg"),
  Learn: require("../../../public/svg/learn.svg"),
};

export default {
  name: 'PerformanceItem',
  props: {
    metric: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
      validator(value) {
        return Object.prototype.hasOwnProperty.call(icons, value)
      }
    },
    title: {
      type: String,
      required: true,
    },
    components: {},
  },
  computed: {
    iconType() {
      return icons[this.icon];
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
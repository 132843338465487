<template>
  <input
    class="w-full rounded border-2 border-grey-350 bg-transparent p-4 placeholder-grey-450::placeholder text-white font-semibold"
    :class="{'border-silver text-silver placeholder-silver': isAlternate}"
    :name="name"
    :placeholder="placeholder"
    :type="type"
    @keyup="$emit('keyChange', $event)"
  />
</template>

<script>
export default {
  name: "FormInput",
  props: {
    placeholder: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    isAlternate: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
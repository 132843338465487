<template>
    <svg
    width="132"
    height="32"
    viewBox="0 0 132 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.8155 11.717L18.674 14.6463L24.5325 11.717V0H12.8155V11.717ZM0 22.0634L6.20908 32L16.1457 25.7909L16.1463 18.8823L9.93661 15.8543L0 22.0634ZM37.5587 22.0634L31.3496 32L21.413 25.7909L21.4123 18.8823L27.622 15.8543L37.5587 22.0634Z"
      fill="#83C6F2"
    />
    <path
      d="M73.4948 5.10385H69.5268L63.5108 15.1519L57.5268 5.10385H53.5588L61.6228 18.5439V27.5039H65.3988V18.5439L73.4948 5.10385Z"
      fill="#051A26"
    />
    <path
      d="M87.4158 4.04785V11.8239C85.8798 10.5439 83.9278 9.80785 81.7198 9.80785C76.4718 9.80785 72.7278 13.6799 72.7278 18.8959C72.7278 24.0479 76.4718 27.9519 81.7198 27.9519C84.1198 27.9519 86.2638 27.0559 87.7998 25.5519L88.2798 27.5359H90.9678V4.04785H87.4158ZM81.9758 24.5599C78.7438 24.5599 76.3438 22.1279 76.3438 18.8639C76.3438 15.6319 78.7438 13.1999 81.9758 13.1999C85.2398 13.1999 87.6398 15.6319 87.6398 18.8639C87.6398 22.0959 85.2398 24.5599 81.9758 24.5599Z"
      fill="#051A26"
    />
    <path
      d="M112.455 18.9279C112.455 13.9039 108.935 9.80785 103.623 9.80785C98.5671 9.80785 94.7591 13.7119 94.7591 18.8639C94.7591 24.0479 98.5671 27.9519 103.623 27.9519C107.495 27.9519 110.663 25.7119 112.039 22.0639H108.295C107.367 23.6639 105.703 24.6239 103.623 24.6239C100.807 24.6239 98.7911 22.8959 98.3431 20.0799H112.359C112.423 19.6959 112.455 19.3119 112.455 18.9279ZM103.623 13.0399C106.279 13.0399 108.231 14.5759 108.871 17.1999H98.4391C99.0151 14.6399 100.967 13.0399 103.623 13.0399Z"
      fill="#051A26"
    />
    <path
      d="M127.535 10.2239L122.479 23.0559L117.295 10.2239H113.551L120.847 27.5039H124.079L131.183 10.2239H127.535Z"
      fill="#051A26"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>
<template>
  <div class="mt-10">
    <div class="flex space-x-4">
      <p
        class="py-1 px-2 border-b-2 border-transparent cursor-pointer"
        :class="{
          'text-black-550 font-semibold border-primary': tab.isActive,
          'text-grey-450': !tab.isActive,
        }"
        v-for="(tab, idx) in tabs"
        :key="tab.name"
        @click="changeTab(idx)"
      >
        {{ tab.name }}
      </p>
    </div>
    <div class="grid grid-cols-12 mt-10 gap-x-10">
      <div class="col-span-7 bg-smoke p-10 pb-8 space-y-4 rounded self-start">
        <div
          class="flex justify-between pb-4 border-b border-gray-350"
          v-for="(courseWork, index) in courseWorks"
          :key="courseWork.title + index"
        >
          <p class="text-xl font-normal">{{ courseWork.title }}</p>
          <p class="text-xl font-semibold">
            {{ courseWork.hours | hourString }}
          </p>
        </div>
        <div class="flex justify-between">
          <p class="text-xl font-bold text-green-550">13 Course in Total</p>
          <p class="text-xl font-bold text-green-550">
            {{ totalCourseHours | hourString }}
          </p>
        </div>
      </div>
      <div class="bg-accent col-span-5 p-10 flex flex-col self-start">
        <div class="space-y-4">
          <div>
            <p class="text-grey-350">Next Program Start Date</p>
            <h5 class="mt-1">March 4th, 2021</h5>
          </div>
          <div>
            <p class="text-grey-350">Training Duration</p>
            <h5 class="mt-1">2 Months</h5>
          </div>
          <div>
            <p class="text-grey-350">Estimated Hours</p>
            <h5 class="mt-1">{{ totalCourseHours | hourString }}</h5>
          </div>
        </div>
        <form class="space-y-4  mt-14">
          <FormInput name="firstname" placeholder="First Name" type="text" isAlternate />
          <FormInput name="email" placeholder="Email" type="email" isAlternate />
          <button type="submit" class="btn btn--primary w-full">Enroll and Download Syllabus</button>
          <div class="flex justify-center items-center">
            <Shuttle />
            <p class="text-grey-350 text-center ml-2">Courses start every two weeks</p>
          </div>
        </form>
        <div class="mt-4">
          <div class="btn btn--primary">Download Syllabus only</div>
          <p class="text-grey-350 text-center mt-2">Download with submitting your details</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormInput from '@/components/blocks/FormInput'
import Shuttle from '../../../public/svg/shuttle.svg'

export default {
  name: "Curriculum",
  data() {
    return {
      tabs: [
        {
          name: "Design",
          isActive: true,
        },
        {
          name: "Product",
          isActive: false,
        },
        {
          name: "Data Science",
          isActive: false,
        },
        {
          name: "IT Ops",
          isActive: false,
        },
        {
          name: "Software Development",
          isActive: false,
        },
      ],
      courseWorks: [
        {
          title: "Introduction to Design",
          hours: 5,
        },
        {
          title: "Fundamentals of UX",
          hours: 2,
        },
        {
          title: "Creating a Design System",
          hours: 5,
        },
        {
          title: "Low Fidelity Design",
          hours: 2,
        },
        {
          title: "High Fidelity Design",
          hours: 4,
        },
        {
          title: "Low Fidelity Design",
          hours: 2,
        },
        {
          title: "Product Animation",
          hours: 4,
        },
        {
          title: "Prototyping",
          hours: 2,
        },
        {
          title: "A/B Testing",
          hours: 4,
        }

      ],
    };
  },
  components: {
    FormInput,
    Shuttle
  },
  computed: {
    totalCourseHours() {
      return this.courseWorks.reduce((acc, courseWork) => ({
        hours: acc.hours + courseWork.hours,
      })).hours;
    },
  },
  methods: {
    changeTab(index) {
      this.tabs = this.tabs.map((tab, idx) => {
        tab["isActive"] = idx === index;
        return tab;
      });
    },
  },
};
</script>

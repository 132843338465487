<template>
  <Container class="bg-blackish pt-44">
    <div class="relative">
      <div class="flex space-x-3 absolute -top-14 right-14">
        <Quote />
        <Quote />
      </div>
      <img
        class="object-cover rounded"
        src="@/assets/images/our-people.png"
        alt="our people"
        loading="lazy"
        width="100%"
      />
    </div>
    <div class="grid grid-cols-2 gap-x-24 mt-12 pb-28">
      <div class="">
        <h1 class="text-white">Not sure what to do?</h1>
        <p class="text-xl text-grey-350 mt-6">
          Our schools are designed to help you acquire skills with real-world
          applications. With project based active learning, your mastery of the
          topics translates into skills that put you in the top percentile in
          the labour market.
        </p>
      </div>
      <div class="mt-auto">
        <div class="flex flex-col w-2/3 space-y-6">
          <a href="mailto:test@gmail.com" class="btn btn--primary text-center"
            >Send us an email</a
          >
          <a href="tel:+2348020349833" class="btn btn--primary text-center"
            >Call +2348020349833</a
          >
        </div>
      </div>
    </div>
    <div class="grid grid-cols-2 mt-44">
      <div>
        <div class="grid grid-cols-4 gap-x-8">
          <FooterLinkList
            :title="programDataLink.title"
            :links="programDataLink.links"
          />
          <FooterLinkList
            :title="enterpriseDataLink.title"
            :links="enterpriseDataLink.links"
          />
          <FooterLinkList
            :title="companyDataLink.title"
            :links="companyDataLink.links"
          />
          <FooterLinkList
            :title="legalDataLink.title"
            :links="legalDataLink.links"
          />
        </div>
        <div class="flex flex-row space-x-4 mb-10 mt-20">
          <a href="/">
            <LinkedIn />
          </a>

          <a href="/">
            <Facebook />
          </a>

          <a href="/">
            <Twitter />
          </a>

          <a href="/">
            <Youtube />
          </a>
        </div>
        <p class="text-gray-400 font-semibold">
          © 2021 Ydev.com Inc. All Rights Reserved
        </p>
      </div>

      <div class="flex flex-row justify-end">
        <Illustration />
      </div>
    </div>
  </Container>
</template>

<script>
import Container from "@/components/blocks/Container";
import FooterLinkList from "@/components/blocks/FooterLinkList";
import Illustration from "../../../public/svg/footer-illustration.svg";
import Facebook from "../../../public/svg/facebook.svg";
import Twitter from "../../../public/svg/twitter.svg";
import Youtube from "../../../public/svg/youtube.svg";
import LinkedIn from "../../../public/svg/linkedin.svg";
import Quote from "../../../public/svg/quote.svg";

export default {
  name: "Footer",
  components: {
    Container,
    Illustration,
    FooterLinkList,
    Facebook,
    Twitter,
    LinkedIn,
    Youtube,
    Quote,
  },
  data() {
    return {
      programDataLink: {
        title: "Program",
        links: [
          {
            path: "/program",
            name: "Design School",
          },
          {
            path: "/program",
            name: "Data School",
          },
          {
            path: "/program",
            name: "Product School",
          },
          {
            path: "/program",
            name: "IT-Ops School",
          },
          {
            path: "/program",
            name: "Sofware school",
          },
        ],
      },
      enterpriseDataLink: {
        title: "Enterprise",
        links: [
          {
            path: "/enterprise",
            name: "Ydev for Enterpise",
          },
        ],
      },
      companyDataLink: {
        title: "Company",
        links: [
          {
            path: "/about",
            name: "About",
          },
          {
            path: "/",
            name: "Support",
          },
          {
            path: "/",
            name: "Help and FAQ",
          },
          {
            path: "/",
            name: "Become an Instructor",
          },
        ],
      },
      legalDataLink: {
        title: "Legal",
        links: [
          {
            path: "/",
            name: "Privacy Policy",
          },
          {
            path: "/terms-of-use",
            name: "Terms of use",
          },
        ],
      },
    };
  },
};
</script>

<style lang="scss" scoped>
</style>
import Vue from 'vue'
import App from './App.vue'
import './tailwind.css'
import router from './router'


Vue.config.productionTip = false

Vue.filter('hourString', function (hours) {
  if (!hours) return ''
  return `${hours} hours`
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

<template>
  <div class="mt-24">
    <div class="grid grid-cols-5">
      <div class="col-span-2"></div>
      <div class="bg-white shadow-ydev transform scale-110 py-4"></div>
      <div class="bg-white self-start"></div>
      <div class="bg-white self-start"></div>
    </div>
    <div class="grid grid-cols-5 border-b border-grey-250">
      <div class="col-span-2 py-10"></div>
      <div
        class="bg-white flex items-center justify-center px-8 shadow-ydev transform scale-110 -mt-4"
      >
        <Logo />
      </div>
      <div class="bg-white flex items-center justify-center border-r border-grey-250">
        <div class="inline-block">
          <h5 class="text-silver">Decagon</h5>
        </div>
      </div>
      <div class="bg-white flex items-center justify-center">
        <div class="inline-block">
          <h5 class="text-silver">Semicolon</h5>
        </div>
      </div>
    </div>
    <div
      class="grid grid-cols-5 border-b border-grey-250"
      v-for="comparison in comparisons"
      :key="comparison.title"
    >
      <div class="col-span-2 py-6">
        <h5 class="w-3/4 font-semibold">{{ comparison.title }}</h5>
      </div>
      <div
        class="bg-white border-t border-grey-250 mt-1  shadow-ydev transform scale-110"
      >
        <div class="flex justify-center items-center w-full h-full">
          <template v-if="comparison.ydev">
            <Active />
          </template>
          <template v-else>
            <Inactive />
          </template>
        </div>
      </div>
      <div class="bg-white flex justify-center items-center w-full border-r border-grey-250">
        <template v-if="comparison.decagon">
          <Active />
        </template>
        <template v-else>
          <Inactive />
        </template>
      </div>
      <div class="bg-white flex justify-center items-center w-full">
        <template v-if="comparison.semicolon">
          <Active />
        </template>
        <template v-else>
          <Inactive />
        </template>
      </div>
    </div>
    <div class="grid grid-cols-5">
      <div class="col-span-2 py-14"></div>
      <div
        class="bg-white border-t border-grey-250 flex items-center justify-center px-8 shadow-ydev transform scale-110"
      >
        <div class="btn btn--primary w-full -mb-6">Get Started</div>
      </div>
      <div class="bg-white flex items-center justify-end">
        <div class="inline-block">
          <div class="btn btn--outline text-base">View more comparison</div>
        </div>
      </div>
      <div class="bg-white"></div>
    </div>
    <div class="grid grid-cols-5">
      <div class="col-span-2"></div>
      <div class="bg-white shadow-ydev transform scale-110 py-4"></div>
      <div class="bg-white self-start"></div>
      <div class="bg-white self-start"></div>
    </div>
  </div>
</template>

<script>
import Active from "../../../public/svg/check-icon-active.svg";
import Inactive from "../../../public/svg/check-icon-inactive.svg";
import Logo from "@/components/blocks/Logo";

export default {
  name: "CourseBenefitComparison",
  data() {
    return {
      comparisons: [
        {
          title: "Course learning checks",
          ydev: true,
          decagon: false,
          semicolon: false,
        },
        {
          title: "Video instruction and visualizations",
          ydev: true,
          decagon: false,
          semicolon: false,
        },
        {
          title: "Online and offline training",
          ydev: true,
          decagon: false,
          semicolon: false,
        },
        {
          title: "Technical mentor support",
          ydev: true,
          decagon: false,
          semicolon: false,
        },
      ],
    };
  },
  components: {
    Active,
    Inactive,
    Logo,
  },
};
</script>

<style lang="scss" scoped>
</style>